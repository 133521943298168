import { CheckoutForm } from "../../components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Container, Form, Title, Wrapper } from "./styled";

export const Payment = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const publishableKey = queryParams.get("publishable_key");
  const paymentIntentSecret = queryParams.get("payment_intent_secret");

  const stripePromise = loadStripe(publishableKey);
  const options = {
    clientSecret: paymentIntentSecret,
    appearance: {
      theme: "night",
      labels: "floating",
      variables: {
        colorPrimary: "#dc163b",
      },
    },
  };

  return (
    <Container>
      <Wrapper>
        <Form>
          <Title>Pay by card</Title>
          {(!publishableKey || !paymentIntentSecret) && <span>error</span>}
          {publishableKey && paymentIntentSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          )}
        </Form>
      </Wrapper>
    </Container>
  );
};
